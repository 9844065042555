import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "./layout";

export default function Brands() {
  const data = useStaticQuery(graphql`
    query BrandsQuery {
      allStrapiBrand {
        edges {
          node {
            brand
            name
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);

  const brands = data.allStrapiBrand.edges;

  return (
    <Layout>
      <div className="max-w-7xl mx-auto md:py-16">
        <div className="py-4 font-header font-bold text-2xl text-graymachete">
          Бренды
        </div>
        <div className="grid md:grid-cols-6 grid-cols-2 gap-y-14 gap-x-4 py-10">
          {brands.map((brand) => {
            return (
              <div
                key={brand.node.name}
                className="flex flex-col hover:shadow-lg self-center items-center"
              >
                <div className="items-center space-y-4">
                  <GatsbyImage
                    image={
                      brand.node.images.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={brand.node.name}
                    title={brand.node.name}
                  />
                  <div className="text-center py-4 font-bold font-header text-gray-400 hover:text-gray-900 uppercase">
                    {brand.node.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
